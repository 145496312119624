import i18n from "i18next";
import backend from "i18next-xhr-backend";

// import deStrings from "./languages/de";
import enStrings from "./languages/en";
// import esStrings from "./languages/es";
// import frStrings from "./languages/fr";
// import hiStrings from "./languages/hi";
// import itStrings from "./languages/it";
// import jaStrings from "./languages/ja";
// import koStrings from "./languages/ko";
// import ptBrStrings from "./languages/pt-br";
// import ruStrings from "./languages/ru";
// import zhStrings from "./languages/zh";
// import thStrings from "./languages/th";

const resources = {
  // de: deStrings,
  en: enStrings,
  // es: esStrings,
  // fr: frStrings,
  // hi: hiStrings,
  // it: itStrings,
  // ja: jaStrings,
  // ko: koStrings,
  // "pt-rBR": ptBrStrings, // Android
  // pt: ptBrStrings, // iOS
  // ru: ruStrings,
  // zh: zhStrings, // Android
  // "zh-Hans": zhStrings, // iOS
  // th: thStrings,
};

const locales = Object.keys(resources);

i18n.use(backend).init({
  resources: resources,
  lng: window.clientConfig.locale,
  fallbackLng: "en",
  debug: window.appConfig.isDebug,
  ns: ["strings"],
  defaultNS: "strings",
  keySeparator: ".",
  interpolation: {
    escapeValue: false,
    formatSeparator: ","
  },
  react: {
    wait: true
  }
}, () => {
  window.axios.defaults.headers.common["X-Locale"] = i18n.language;
});

export default i18n;

export function getCurrentLocale() {
  return i18n.languages.find((lng) => locales.indexOf(lng) !== -1);
}
