import React from "react";

export default class ImageView extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isStarted: false,
      computedHeight: 0,
    };

    this.image = new Image();
    this.image.onload = () => {
      this.props.onImageLoaded && this.props.onImageLoaded();
      this.setState({isLoading: false});
    };

    this.rectElementRef = React.createRef();
    this.checkScrollTimer = null;
    this.checkResizeTimer = null;

    this.prepare = this.prepare.bind(this);
    this.load = this.load.bind(this);
    this.checkScroll = this.checkScroll.bind(this);
    this.checkResize = this.checkResize.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.renderAsInline = this.renderAsInline.bind(this);
    this.renderAsBackground = this.renderAsBackground.bind(this);
  }

  componentDidMount() {
    this.prepare();

    window.addEventListener("resize", this.checkResize);
    this.checkScrollTimer = setInterval(this.checkScroll, 50);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.checkResize);
    clearInterval(this.checkScrollTimer);
    clearTimeout(this.checkResizeTimer);
    this.image.onload = null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.image !== this.props.image) {
      clearInterval(this.checkScrollTimer);
      clearTimeout(this.checkResizeTimer);
      this.prepare();
      this.load();
    }
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return this.state.isLoading !== nextState.isLoading
      || this.state.isStarted !== nextState.isStarted
      || this.state.computedHeight !== nextState.computedHeight
      || (!this.props.image && nextProps.image)
      || (this.props.image && nextProps.image && this.props.image.url !== nextProps.image.url);
  }

  checkResize() {
    clearTimeout(this.checkResizeTimer);
    this.checkResizeTimer = setTimeout(this.prepare, 1000 / 15);
  }

  checkScroll() {
    const rect = this.rectElementRef.current.getBoundingClientRect();
    if (rect.y < window.innerHeight) {
      clearInterval(this.checkScrollTimer);
      this.load();
    }
  }

  prepare() {
    const rect = this.rectElementRef.current.getBoundingClientRect();

    this.setState({
      computedHeight: this.props.square
        ? rect.width
        : Math.round(rect.width * (this.props.image.height / this.props.image.width))
    });
  }

  load() {
    this.setState({
      isLoading: true,
      isStarted: true,
    }, () => {
      this.image.src = this.props.image.url;
    });
  }

  handleClick(e) {
    this.props.onClick && this.props.onClick(e);
  }

  render() {
    const isImageDisplayed = this.props.image && this.state.isStarted && !this.state.isLoading;

    if (this.props.backgrounded) {
      return this.renderAsBackground(isImageDisplayed);
    } else {
      return this.renderAsInline(isImageDisplayed);
    }
  }

  renderAsInline(isImageDisplayed) {
    return <div
      ref={this.rectElementRef}
      className={`imageview-host imageview-host--inline ${this.props.hostClassName || ""}`}
      style={{height: this.state.computedHeight}}>

      {isImageDisplayed && <img
        className={`imageview-image ${this.props.imageClassName || ""}`}
        src={this.props.image.url}
        alt={this.props.alt || ""}
        style={{height: this.state.computedHeight}}
        onClick={this.handleClick} />}
    </div>;
  }

  renderAsBackground(isImageDisplayed) {
    return <div
      ref={this.rectElementRef}
      className={`imageview-host imageview-host--background ${this.props.hostClassName || ""}`}
      style={{height: this.state.computedHeight}}>

      {isImageDisplayed && <div
        className={`imageview-holder ${this.props.imageClassName || ""}`}
        style={{backgroundImage: `url(${this.props.image.url})`}}
        onClick={this.handleClick} />}
    </div>;
  }
}
