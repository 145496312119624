import {Photo} from "../data/Photo";
import {Creative} from "../data/Creative";
import {Task} from "../data/Task";
import {Suggestion} from "../data/Suggestion";

export const apiResponseErrorCodes = {
  INTERNAL: 1,
  INVALID_PARAMS: 2,
  PHOTOLAB: 3,
  NOT_AUTHORIZE: 401,
  NOT_FOUND: 404,
  DELETED: 410,
  FILE_FORMAT_INVALID: 415,
};

export class ApiResponseError extends Error {

  constructor(data) {
    super();

    this.name = "ApiResponseError";
    this.code = data.error_code;
    this.message = `Code: ${data.error_code}, Message: ${data.error_message}`;
    this.response = data;
  }
}

function checkApiResponse(res) {
  if (res.data.error_code) {
    throw new ApiResponseError(res.data);
  } else {
    return res.data;
  }
}

function mapPhotoResponse(res) {
  return {
    photo: new Photo(res.photo),
    creatives: res.creatives.map((item) => new Creative(item)),
  };
}

function mapTaskResult(res) {
  return new Task(res);
}

function paramsToStr(params = {}, random = true) {
  if (random) {
    params.r = Math.random();
  }

  return Object.keys(params)
    .map((key) => `${key}=${encodeURIComponent(params[key])}`)
    .join("&");
}

function logEvent(eventId, eventParams, userParams, webviewParams) {
  return window.axios.post(window.appConfig.analytics.endpoint, {
    id: eventId,
    params: eventParams,
    user_params: userParams,
    webview_params: webviewParams,
  }).then(checkApiResponse);
}

function createPhoto(image, data, params) {
  params = params || {};

  const formData = new FormData();

  if (image instanceof File) {
    formData.append("file", image);
  } else {
    formData.append("image_url", image);
  }

  if (data) {
    formData.append("image_data", JSON.stringify(data || {}));
  }

  Object.keys(params).forEach((pk) => formData.append(pk, params[pk]));

  const reqParams = {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  };

  return window.axios.post(window.appConfig.paths.apiUpload + "/photos/create", formData, reqParams)
    .then(checkApiResponse)
    .then(mapPhotoResponse);
}

function attachImageToPhoto(photoId, image, data, params) {
  params = params || {};

  const formData = new FormData();

  if (image instanceof File) {
    formData.append("file", image);
  } else {
    formData.append("image_url", image);
  }

  if (data) {
    formData.append("image_data", JSON.stringify(data || {}));
  }

  Object.keys(params).forEach((pk) => formData.append(pk, params[pk]));

  const reqParams = {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  };

  return window.axios.post(window.appConfig.paths.apiUpload + "/photos/" + photoId + "/attach", formData, reqParams)
    .then(checkApiResponse)
    .then(mapPhotoResponse);
}

function fetchPhotoById(id, params = {}) {
  return window.axios.get(window.appConfig.paths.api + "/photos/id/" + id + "?" + paramsToStr(params))
    .then(checkApiResponse)
    .then(mapPhotoResponse);
}

function fetchPhotoByHash(hash, params = {}) {
  return window.axios.get(window.appConfig.paths.api + "/photos/hash/" + hash + "?" + paramsToStr(params))
    .then(checkApiResponse)
    .then(mapPhotoResponse);
}

function createTask(type, params) {
  return window.axios.post(window.appConfig.paths.api + "/tasks/create", {type, params})
    .then(checkApiResponse)
    .then(mapTaskResult);
}

function fetchTask(taskId) {
  return window.axios.get(window.appConfig.paths.api + "/tasks/" + taskId)
    .then(checkApiResponse)
    .then(mapTaskResult);
}

function fetchSuggestions() {
  return window.axios.get(window.appConfig.paths.api + "/suggestions")
    .then(checkApiResponse)
    .then((res) => res.map((item) => new Suggestion(item)));
}

function fetchSuggestionImages(term, take = 100) {
  const params = {
    q: term.toLowerCase().replace(" ", "+"),
    count: take,
  };

  return window.axios.get(window.appConfig.paths.apiImages + "/suggestions/images?" + paramsToStr(params, false))
    .then((res) => {
      return res.data.value.map((item) => {
        return {
          url: item.contentUrl,
          width: item.width,
          height: item.height,
          extension: item.encodingFormat,
          thumbnail: {
            url: item.thumbnailUrl,
            width: item.thumbnail.width,
            height: item.thumbnail.height,
          }
        };
      });
    });
}

function hitSuggestion(term) {
  return window.axios.post(window.appConfig.paths.api + "/suggestions/hit", {suggestion: term})
    .then(() => {})
    .catch(() => {});
}

export default {
  logEvent,
  createPhoto,
  fetchPhotoById,
  fetchPhotoByHash,
  attachImageToPhoto,
  createTask,
  fetchTask,
  fetchSuggestions,
  fetchSuggestionImages,
  hitSuggestion,
};