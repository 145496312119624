import React from 'react';
import AppContext from "../../contexts/AppContext";
import Loading from "../components/Loading";
import {hits, hitEvent, userEvents, logEvent} from "../../utils/log";
import {generatePath} from "react-router";
import routes from "../../routes";
import api from "../../utils/api";
import i18n from "../../i18n";
import FileChooseButton from "../components/FileChooseButton";
import {assetUrl} from "../../utils/etc";
import ErrorModal from "../modals/ErrorModal";

export default class CreatePage extends React.Component {

  state = {
    isLoading: false,
  };

  componentDidMount() {
    logEvent(userEvents.PAGE_STEP_1);
  }

  handleFileSelected = (file) => {
    hitEvent(hits.PHOTO_SELECT);
    logEvent(userEvents.PHOTO_SELECT, {page: "create"});

    this.setState({isLoading: true}, () => {
      api.createPhoto(file).then((res) => {
        hitEvent(hits.PHOTO_UPLOADED);
        logEvent(userEvents.PHOTO_UPLOADED);
        this.props.history.replace(generatePath(routes.PROCESSING, {id: res.photo.id}));
      }).catch((err) => {
        logEvent(hits.PHOTO_UPLOAD_FAILED);
        logEvent(userEvents.PHOTO_UPLOAD_FAILED);

        const button = <FileChooseButton
          className="btn-select"
          children={i18n.t("select_another_photo")}
          onFileSelected={(file) => {
            this.context.popModal();
            this.handleFileSelected(file);
          }} />;

        this.context.pushModal(<ErrorModal
          key="CreatePage_ErrorModal"
          error={err}
          buttons={button}
        />);
      });
    });
  };

  render() {
    const props = {
      ...this.state,
      onFileSelected: this.handleFileSelected,
    };

    return <CreatePageView {...props} />;
  }
}

CreatePage.contextType = AppContext;

class CreatePageView extends React.Component {
  render() {
    if (this.props.isLoading) {
      return <Loading text={i18n.t("loader_uploading")} />;
    }

    return <main className="create-page">
      <div className="container">
        <h2 className="step-title"
          dangerouslySetInnerHTML={{__html: i18n.t("step1_title")}} />
        <p className="step-text" dangerouslySetInnerHTML={{__html: i18n.t("step1_text")}} />
        <FileChooseButton 
          className="btn-select"
          onFileSelected={this.props.onFileSelected}
          children={i18n.t("btn_choose_foto")} />

        <picture>
          <source srcSet={assetUrl("assets/img/bg/top-d.png")} alt="bg" media="(min-width: 780px)" />
          <img className="bg-top" srcSet={assetUrl("assets/img/bg/top.png")} alt="bg" />
        </picture>
        <img className="bg-bottom" src={assetUrl("assets/img/bg/bottom.png")} alt="bg"/>
      </div>
    </main>;
  }
}