import React from 'react';
import AppContext from "../../contexts/AppContext";
import Loading from "../components/Loading";
import {SvgSprite} from "../components/SvgSprite";
import api from "../../utils/api";
import {assetUrl} from "../../utils/etc";
import i18n from "../../i18n";
import routes from "../../routes";
import DownloadModal from "../modals/DownloadModal";
import ImageView from "../components/ImageView";
import {logEvent, userEvents} from "../../utils/log";

const groups = {
  nomination1: "nomination_1",
  nomination2: "nomination_2",
  nomination3: "nomination_3",
  nomination4: "nomination_4",
  nominationBonus1: "nomination_bonus_1",
};

const stableGroups = [
  groups.nomination1,
  groups.nomination2,
  groups.nomination3,
  groups.nomination4,
];

const groupsIcons = {
  [groups.nomination1]: "oscar1",
  [groups.nomination2]: "oscar2",
  [groups.nomination3]: "oscar3",
  [groups.nomination4]: "oscar4",
  [groups.nominationBonus1]: "oscar5",
};

export default class ResultPage extends React.Component {

  state = {
    isLoading: true,
    photo: null,
    creatives: [],
    groups: stableGroups,
    selectedGroup: groups.nomination1,
    initialGroup: groups.nomination1,
  };

  componentDidMount() {
    logEvent(userEvents.PAGE_RESULT);

    this.fetchTimer = null;
    this.fetchPhoto();
  }

  componentWillUnmount() {
    clearTimeout(this.fetchTimer);
  }

  fetchPhoto = () => {
    api.fetchPhotoByHash(this.props.match.params.hash)
      .then(this.handleFetchPhotoSuccess)
      .catch(this.handleFetchPhotoError);
  };

  handleFetchPhotoSuccess = ({photo, creatives}) => {
    const isFirstLoad = this.state.photo === null;
    const nextState = {
      isLoading: false,
      photo,
      creatives: creatives.filter((creative) => creative.alias === "result"),
    };

    if (isFirstLoad) {
      const processedCreative = nextState.creatives.find((creative) => creative.isProcessed);
      nextState.initialGroup = processedCreative.group;
      nextState.selectedGroup = processedCreative.group;
      logEvent(userEvents.TAB_INITIAL, {group: nextState.initialGroup});
    }

    if (nextState.creatives.count((creative) => creative.isProcessing) > 0) {
      this.fetchTimer = setTimeout(this.fetchPhoto, 1000);
    }

    // бонусный добавляем, только если он отпроцессился
    const bonusCreative = nextState.creatives.find((creative) => {
      return creative.group === groups.nominationBonus1
        && creative.isSelected
        && creative.isProcessed;
    });

    if (bonusCreative) {
      if (this.state.groups.indexOf(groups.nominationBonus1) === -1) {
        const nextGroups = this.state.groups.slice();
        nextGroups.push(groups.nominationBonus1);

        nextState.groups = nextGroups;
      }
    }

    this.setState(nextState);
  };

  handleFetchPhotoError = (err) => {
    console.error(err);
    this.fetchTimer = setTimeout(this.fetchPhoto, 1000);
  };

  handleCreateNewButtonClicked = () => {
    this.props.history.push(routes.CREATE);
  };

  handleDownloadButtonClicked = () => {
    const creative = this.state.creatives.find((creative) => {
      return creative.group === this.state.selectedGroup
    });

    this.context.pushModal(<DownloadModal
      key="ResultPage_DownloadModal"
      photo={this.state.photo}
      creative={creative}
    />);
  };

  handleGroupSelected = (group) => {
    logEvent(userEvents.TAB_SELECT, {
      group,
      prev_group: this.state.selectedGroup,
      initial_group: this.state.initialGroup,
    });

    this.setState({selectedGroup: group});
  };

  handleCreativeLoaded = (creative) => {
    logEvent(userEvents.CREATIVE_VIEW, {
      photo_id: this.state.photo.id,
      creative_id: creative.id,
      group: creative.group,
    });
  };

  render() {
    const props = {
      ...this.state,
      onGroupSelect: this.handleGroupSelected,
      onCreateNewButtonClick: this.handleCreateNewButtonClicked,
      onDownloadButtonClick: this.handleDownloadButtonClicked,
      onCreativeLoaded: this.handleCreativeLoaded,
    };

    return <ResultPageView {...props} />;
  }
}

ResultPage.contextType = AppContext;

class ResultPageView extends React.Component {

  render() {
    if (this.props.isLoading) {
      return <Loading />;
    }

    const selectedCreative = this.props.creatives.find((creative) => {
      return creative.group === this.props.selectedGroup && creative.isSelected;
    });

    return <main className="result-page">
      <div className="container">
        <div className="title-container">
          <h2 className="main-title"
            dangerouslySetInnerHTML={{__html: i18n.t("main_title")}} />
          <span className="main-subtitle"
            dangerouslySetInnerHTML={{__html: i18n.t("main_title_continue")}} />
        </div>

        <div className="result-container">
          <div className="tabs-btns">
            {this.props.groups.map((group) => this.renderTab(group))}
          </div>

          {this.renderCreative(selectedCreative)}
        </div>
        
        {this.renderButtons(selectedCreative)}

        <img className="bg-bottom" src={assetUrl("assets/img/bg/bottom.png")} alt="bg"/>
      </div>
      <picture>
        <source srcSet={assetUrl("assets/img/bg/top-d.png")} media="(min-width: 780px)" />
        <img className="bg-top" srcSet={assetUrl("assets/img/bg/top.png")} alt="bg" />
      </picture>
    </main>;
  }

  renderTab = (group) => {
    const classNames = ["tab--" + group];
    if (this.props.selectedGroup === group) {
      classNames.push("active");
    }

    return <button
      key={group}
      className={classNames.join(" ")}
      onClick={() => this.props.onGroupSelect(group)}>
      <SvgSprite viewBox="0 0 30 36" icon={`icon-${groupsIcons[group]}`} />
    </button>;
  };

  renderCreative = (creative) => {
    if (creative && creative.isProcessed) {
      return <div className="creative-container">
        <ImageView
          image={creative.file}
          onImageLoaded={() => this.props.onCreativeLoaded(creative)}
          backgrounded />
        <Loading class="creative-loader" />
      </div>
    }

    return <Loading class="creative-loader" />;
  };

  renderButtons = (creative) => {
    return <div className="btns-container" hidden={!creative || !creative.isProcessed}>
      <button className="btn-try" onClick={this.props.onCreateNewButtonClick}>
        <SvgSprite viewBox="0 0 24 22" icon="icon-camera" />
        <span dangerouslySetInnerHTML={{__html: i18n.t("btn_another_foto")}} />
      </button>
      <button className="btn-download" onClick={this.props.onDownloadButtonClick}>
        <span className="btn-download-icon">
          <SvgSprite viewBox="0 0 24 22" icon="icon-download" />
        </span>
        <span className="btn-download-text" dangerouslySetInnerHTML={{__html: i18n.t("btn_download_foto")}} />
      </button>
    </div>;
  }
}