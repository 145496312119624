export class Creative {

  static STATUS_FAILED = -1;
  static STATUS_PENDING = 0;
  static STATUS_PROCESSING = 1;
  static STATUS_PROCESSED = 2;

  _data = {};

  constructor(data) {
    this._data = data;
  }

  get id() {
    return this._data.id;
  }

  get group() {
    return this._data.group;
  }

  get alias() {
    return this._data.alias;
  }

  get status() {
    return this._data.status;
  }

  get file() {
    return this._data.file;
  }

  get extra() {
    return this._data.extra;
  }

  get template() {
    return this._data.template;
  }

  get error() {
    return this._data.error;
  }

  get errorCode() {
    return this._data.error_code;
  }

  get isSelected() {
    return this._data.is_selected;
  }

  get isPending() {
    return this.status === Creative.STATUS_PENDING;
  }

  get isProcessing() {
    return this.status === Creative.STATUS_PROCESSING || this.isPending;
  }

  get isProcessed() {
    return this.status === Creative.STATUS_PROCESSED;
  }

  get isFailed() {
    return this.status === Creative.STATUS_FAILED;
  }
}