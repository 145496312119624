import React from 'react';
import routes from "../../routes";
import i18n from "../../i18n";
import {assetUrl} from "../../utils/etc";
import AppContext from "../../contexts/AppContext";
import {SvgSprite} from "../components/SvgSprite";
import Slider from "react-slick";
import {logEvent, userEvents} from "../../utils/log";

export default class IndexPage extends React.Component {

  componentDidMount() {
    logEvent(userEvents.PAGE_INDEX);
  }

  handleStartButtonClicked = () => {
    this.props.history.push(routes.CREATE);
  };

  render() {
    const props = {
      onStartButtonClick: this.handleStartButtonClicked
    };

    return <IndexPageView {...props} />;
  }
}

IndexPage.contextType = AppContext;

class IndexPageView extends React.Component {
  render() {
    const sliderSettings = {
      autoplay: true,
      autoplaySpeed: 3000,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: '0',
      arrows: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 780,
          settings: {
            slidesToShow: 1,
            centerPadding: '40px',
            arrows: false
          }
        }
      ]
    };

    const indexImages = [1, 2, 4, 3];

    return <main className="main-page">
      <div className="container">
        <div className="title-container">
          <h2 className="main-title"
            dangerouslySetInnerHTML={{__html: i18n.t("main_title")}} />
          <span className="main-subtitle"
            dangerouslySetInnerHTML={{__html: i18n.t("main_title_continue")}} />
        </div>
        
        <p className="main-text" dangerouslySetInnerHTML={{__html: i18n.t("main_subtitle")}} />

        <picture>
          <source srcSet={assetUrl("assets/img/bg/top-d.png")} media="(min-width: 780px)" />
          <img className="bg-top" srcSet={assetUrl("assets/img/bg/top.png")} alt="bg" />
        </picture>
        <img className="bg-bottom" src={assetUrl("assets/img/bg/bottom.png")} alt="bg" />
      </div>

      <Slider {...sliderSettings} className="main-slider">
        {indexImages.map((number) => <SlideView key={number} number={number} />)}
      </Slider>

      <div className="container">
        <button
          className="btn-select"
          dangerouslySetInnerHTML={{__html: i18n.t("btn_start")}}
          onClick={this.props.onStartButtonClick} />
      </div>
    </main>;
  }
}

export function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div className="slider-arrow slider-arrow-right" onClick={onClick}>
        <SvgSprite viewBox="0 0 60 60" icon="icon-slider-arrow-right" />
    </div>
  );
}

export function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="slider-arrow slider-arrow-left" onClick={onClick}>
        <SvgSprite viewBox="0 0 60 60" icon="icon-slider-arrow-left" />
    </div>
  );
}

export function SlideView({number}) {
  const jpg = [
    assetUrl(`assets/img/slider/zip/slide${number}.jpg`) + " 1x",
    assetUrl(`assets/img/slider/zip/slide${number}@2x.jpg`) + " 2x",
    assetUrl(`assets/img/slider/zip/slide${number}@3x.jpg`) + " 3x"
  ].join(", ");

  const webp = [
    assetUrl(`assets/img/slider/zip/slide${number}.webp`) + " 1x",
    assetUrl(`assets/img/slider/zip/slide${number}@2x.webp`) + " 2x",
    assetUrl(`assets/img/slider/zip/slide${number}@3x.webp`) + " 3x"
  ].join(", ");

  return <div className={`slide-container slide${number}`}>
    <div className="slide">
      <picture>
        <source srcSet={webp} type="image/webp" />
        <img srcSet={jpg} alt="Demo" />
      </picture>
    </div>
    <div className="block-help block-help1">
      <SvgSprite viewBox="0 0 31 64" icon="icon-arrow-down" />
      <span dangerouslySetInnerHTML={{__html: i18n.t("block_help_text1")}} />
    </div>
    <div className="block-help block-help2">
      <SvgSprite viewBox="0 0 45 33" icon="icon-arrow-up" />
      <span dangerouslySetInnerHTML={{__html: i18n.t("block_help_text2")}} />
    </div>
    <div className="block-help block-help3">
      <SvgSprite viewBox="0 0 48 36" icon="icon-arrow-left" />
      <span dangerouslySetInnerHTML={{__html: i18n.t("block_help_text3")}} />
    </div>
  </div>;
}