export class Photo {

  _data = {};

  constructor(data) {
    this._data = data;
  }

  get id() {
    return this._data.id;
  }

  get hash() {
    return this._data.hash;
  }

  get file() {
    return this._data.file;
  }

  get urls() {
    return this._data.urls;
  }

  get isOwner() {
    return this._data.is_owner;
  }
}