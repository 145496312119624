import React from "react";
import PropTypes from "prop-types";
import AppContext from "../../contexts/AppContext";
import Modal from "./Modal";
import {assetUrl} from "../../utils/etc";
import {ApiResponseError} from "../../utils/api";
import i18n from "../../i18n";

export default class ErrorModal extends Modal {

  renderModal() {
    let message;
    if (this.props.message) {
      message = this.props.message;
    } else if (this.props.error && this.props.error instanceof ApiResponseError) {
      message = i18n.t("error__api_code_" + this.props.error.code);
    } else if (this.props.error && this.props.error.name === "NetworkError") {
      message = i18n.t("error_modal__network_message");
    } else {
      message = i18n.t("error_modal__default_message");
    }

    return <div className="error-container">
      <div className="error">
        <p className="error-text" dangerouslySetInnerHTML={{__html: message}} />
        {this.props.buttons}
      </div>
      <img className="bg-top" src={assetUrl("assets/img/bg/top.png")} alt="bg"/>
      <img className="bg-bottom" src={assetUrl("assets/img/bg/bottom.png")} alt="bg"/>
    </div>;
  }
}

ErrorModal.contextType = AppContext;
ErrorModal.propTypes = {
  error: PropTypes.object,
  message: PropTypes.string,
  buttons: PropTypes.element.isRequired,
};