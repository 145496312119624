import React from "react";
import ReactDOM from "react-dom";
import {Route, Switch, withRouter} from "react-router";
import {BrowserRouter} from "react-router-dom";
import routes from "./routes";
import ModalWrapper from "./ui/modals/ModalWrapper";
import {AppContextProvider} from "./contexts/AppContext";
import AppContext from "./contexts/AppContext";
import {hitEvent, hits, logEvent, userEvents} from "./utils/log";
import Loading from "./ui/components/Loading";
import IndexPage from "./ui/pages/IndexPage";
import ResultPage from "./ui/pages/ResultPage";
import CreatePage from "./ui/pages/CreatePage";
import ProcessingPage from "./ui/pages/ProcessingPage";
import * as webviewUtils from "./utils/webview";
import * as Sentry from "@sentry/react";
import "./ui/styles/app.scss";

if (window.appConfig.sentry.isEnabled) {
  Sentry.init({
    environment: process.env.NODE_ENV,
    dsn: window.appConfig.sentry.dsn,
  });
  Sentry.setUser({token: window.clientConfig.token});
}

class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  componentDidMount() {
    document.body.classList.add("app-locale--" + window.clientConfig.locale);
    document.body.classList.add("app-locale--" + window.clientConfig.lang);
    document.body.classList.add("app-type--" + (window.clientConfig.isWebview ? "webview" : "web"));

    toggleBodyClass("app-webview--ios", window.clientConfig.isWebviewIOS);
    toggleBodyClass("app-webview--android", window.clientConfig.isWebviewAndroid);

    window.webviewHolders.tabSelected.subscribe((v) => {
      if (v > 0) {
        hitEvent(hits.WEBVIEW_TAB_FOCUS);
        logEvent(userEvents.WEBVIEW_TAB_FOCUS, {
          os: window.clientConfig.isWebviewAndroid
            ? "android"
            : (window.clientConfig.isWebviewIOS ? "ios" : "other")
        });
      }
    });

    if (window.clientConfig.isWebview) {
      webviewUtils.webviewCheckInstalledApps((apps) => {
        this.context.setInstalledApps(apps);
      });
    }
  }

  render() {
    if (this.state.isLoading) {
      return <Loading />;
    }

    return <div>
      <Switch>
        <Route exact path={routes.INDEX} render={props => <IndexPage {...props} />} />
        <Route exact path={routes.CREATE} render={props => <CreatePage {...props} />} />
        <Route exact path={routes.PROCESSING} render={props => <ProcessingPage {...props} />} />
        <Route path={routes.RESULT} render={props => <ResultPage {...props} />} />
      </Switch>
      <ModalWrapper />
    </div>;
  }
}

App.contextType = AppContext;

const AppWithRouter = withRouter(App);

ReactDOM.render(
  <BrowserRouter>
    <AppContextProvider>
      <AppWithRouter />
    </AppContextProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

function toggleBodyClass(className, flag) {
  if (flag) {
    document.body.classList.add(className);
  } else {
    document.body.classList.remove(className);
  }
}