import React from "react";
import i18n from "../../i18n";
import {SvgSprite} from "./SvgSprite";
import {assetUrl} from "../../utils/etc";

const LOADING_TEXT_INTERVAL = 3000;

const textsDefault = [
  i18n.t("processing_text_1"),
  i18n.t("processing_text_2"),
  i18n.t("processing_text_3"),
  i18n.t("processing_text_4"),
  i18n.t("processing_text_5"),
  i18n.t("processing_text_6"),
  i18n.t("processing_text_7"),
];

export default class Loading extends React.Component {

  constructor(props) {
    super(props);

    this.texts = textsDefault;
    this.state = {
      imageIsLoaded: false,
      textIndex: Math.floor(Math.random() * (this.texts.length - 1)),
    };

    this.image = new Image();
    this.image.onload = () => this.setState({imageIsLoaded: true});

    this.updateTextIndexTimer = null;
  }

  componentDidMount() {
    this.updateTextIndexTimer = setInterval(this.updateTextIndex, LOADING_TEXT_INTERVAL);

    if (this.props.image) {
      this.image.src = this.props.image;
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.image !== this.props.image) {
      this.setState({imageIsLoaded: false}, () => {
        this.image.src = this.props.image;
      });
    }
  }

  componentWillUnmount() {
    this.image.onload = () => {};
    clearInterval(this.updateTextIndexTimer);
  }

  updateTextIndex = () => {
    if (this.state.textIndex === this.texts.length - 1) {
      this.setState({textIndex: 0});
    } else {
      this.setState({textIndex: this.state.textIndex + 1});
    }
  };

  render() {
    const text = this.props.text || (this.props.withProcessingText ? this.texts[this.state.textIndex] : "&nbsp;");
    // const imageSrc = this.state.imageIsLoaded && this.props.image;

    return <main className={"loader-container " + this.props.class}>
      <div className="loader">
        <SvgSprite className="loader-star star-small" viewBox="0 0 23.172 23.346" icon="icon-star-small" />
        <SvgSprite className="loader-star star-big" viewBox="0 0 23.172 23.346" icon="icon-star-big" />
      </div>
      <p className="loader-text" dangerouslySetInnerHTML={{__html: text}} />
      <picture>
        <source srcSet={assetUrl("assets/img/bg/top-d.png")} media="(min-width: 780px)" />
        <img className="bg-top" srcSet={assetUrl("assets/img/bg/top.png")} alt="bg" />
      </picture>
      <img className="bg-bottom" src={assetUrl("assets/img/bg/bottom.png")} alt="bg"/>
    </main>;
  }
}
