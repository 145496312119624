import React from "react";
import PropTypes from "prop-types";
import AppContext from "../../contexts/AppContext";
import Modal from "./Modal";
import i18n from "../../i18n";
import {SvgSprite} from "../components/SvgSprite";
import {assetUrl} from "../../utils/etc";
import * as webviewUtils from "../../utils/webview";
import {Photo} from "../../data/Photo";
import {Creative} from "../../data/Creative";
import {logEvent, userEvents} from "../../utils/log";

const shareProviders = {
  facebook: "facebook",
  instagram: "instagram",
  snapchat: "snapchat",
};

export default class DownloadModal extends Modal {

  componentDidMount() {
    window.pushOnBackPressed(() => {
      this.dismiss("backpress");
      return true;
    });

    this.startDownload();
  }

  componentWillUnmount() {
    window.popOnBackPressed();
  }

  startDownload = () => {
    logEvent(userEvents.DOWNLOAD, {
      photo_id: this.props.photo.id,
      creative_id: this.props.creative.id,
    });

    if (window.clientConfig.isWebview) {
      webviewUtils.webviewShareDownload(this.props.creative.file.url);
    } else {
      const url = new URL(this.props.photo.urls.download);
      url.searchParams.append("creative_id", this.props.creative.id);
      window.location.href = url.toString();
    }
  };

  logShare = (provider, extra = {}) => {
    logEvent(userEvents.SHARE, {
      provider,
      photo_id: this.props.photo.id,
      creative_id: this.props.creative.id,
      ...extra
    });
  };

  handleFacebookShare = () => {
    this.logShare(shareProviders.facebook);
    webviewUtils.webviewShareFacebook(null, this.props.creative.file.url);
  };

  handleInstagramShare = () => {
    this.logShare(shareProviders.instagram);
    webviewUtils.webviewShare({
      providers: "[1]",
      imageUrl: encodeURIComponent(this.props.creative.file.url),
      ig_direct: 1,
      close_after_share: 1,
      no_sound: 1,
    });
  };

  handleSnapchatShare = () => {
    this.logShare(shareProviders.snapchat);

    const sw = Math.floor(window.screen.width * (window.devicePixelRatio || 1));
    const stickerSize = window.clientConfig.isWebviewIOS
      ? 320
      : Math.floor(sw * 0.95);

    webviewUtils.webviewShare({
      providers: "[11]",
      caption_text: "",
      attachment_url: encodeURIComponent(window.appConfig.paths.app),
      sticker_url: encodeURIComponent(this.props.creative.file.url),
      sc_sticker_posx: 0.5,
      sc_sticker_posy: 0.5,
      sc_sticker_rotation: 0.3,
      sc_sticker_width: stickerSize,
      sc_sticker_height: stickerSize,
    });
  };

  renderModal() {
    return <div className="modal-container">
      <div className="modal">
        <h2 className="modal-title"
            dangerouslySetInnerHTML={{__html: i18n.t("download_modal_title")}} />
        <p className="modal-text"
           dangerouslySetInnerHTML={{__html: i18n.t("download_modal_text")}} />
        <p className="modal-text-loader"
           dangerouslySetInnerHTML={{__html: i18n.t("download_modal_text_loader")}} />

        <div className="btns-container">
          <button
            className="btn-social btn-fb"
            hidden={window.clientConfig.isWeb || !this.context.installedApps.facebook}
            onClick={this.handleFacebookShare}>
            <SvgSprite viewBox="0 0 24 22" icon="icon-fb" />
          </button>
          <button
            className="btn-social btn-insta"
            hidden={window.clientConfig.isWeb || !this.context.installedApps.instagram}
            onClick={this.handleInstagramShare}>
            <SvgSprite viewBox="0 0 24 22" icon="icon-insta" />
          </button>
          <button
            className="btn-social btn-snap"
            hidden={window.clientConfig.isWeb || !this.context.installedApps.snapchat}
            onClick={this.handleSnapchatShare}>
            <SvgSprite viewBox="0 0 24 22" icon="icon-snap" />
          </button>
          <button
            className="btn-got-it"
            onClick={() => this.dismiss("button")}>
            <span dangerouslySetInnerHTML={{__html: i18n.t("btn_got_it")}} />
          </button>
        </div>

        <button className="btn-download-again" onClick={this.startDownload} hidden={true}>
          <SvgSprite viewBox="0 0 24 22" icon="icon-download" />
          <span dangerouslySetInnerHTML={{__html: i18n.t("btn_download_again")}} />
        </button>
      </div>
      <picture>
        <source srcSet={assetUrl("assets/img/bg/top-d.png")} media="(min-width: 780px)" />
        <img className="bg-top" srcSet={assetUrl("assets/img/bg/top.png")} alt="bg" />
      </picture>
      <img className="bg-bottom" src={assetUrl("assets/img/bg/bottom.png")} alt="bg"/>
    </div>;
  }
}

DownloadModal.contextType = AppContext;
DownloadModal.propTypes = {
  photo: PropTypes.instanceOf(Photo).isRequired,
  creative: PropTypes.instanceOf(Creative).isRequired,
};