module.exports = {
  "strings": {
    "processing_text_1": "Could you ever imagine yourself being the one to give Oscars? 😉",
    "processing_text_2": "Carving your custom-made statuette of gold… 😎",
    "processing_text_3": "If your ceremony was real, whom would choose to be the host? 🤔",
    "processing_text_4": "Fun fact: Leo DiCaprio needed 6 attempts to get his Oscar… 👿",
    "processing_text_5": "Do you know how long it takes to manufacture 50 statuettes? 3 months ⏳",
    "processing_text_6": "BTW, what would your Oscar speech be?… 📜",
    "processing_text_7": "And the Oscar goes to…",

    "loader_uploading": "Uploading…",
    "select_another_photo": "Select another photo",

    "main_title": "<span>And my</span> OSCAR",
    "main_title_continue": "goes to…",
    "main_subtitle": "Your turn to give Oscars! Who do you think deserves one? Use your own custom-made statuette to share your thoughts!",
    "photo_caption_1": "*celebrity or film to award",
    "photo_caption_2": "*your selfie-based Oscar",
    "photo_caption_3": "*your photo",

    "step1_title": "Step 1 out of 2",
    "step1_text": "Choose a portrait photo. <span> (We'll use it to create your custom-made statuette)</span>",

    "step2_title": "Step 2 out of 2",
    "step2_text": "Now search for or give us a celeb's photo or a film poster <span> you'd like to award</span>",

    "crop_title": "Crop photo",
    "crop_subtitle": "Click and drag to adjust position. Use scroll to zoom in or out.",

    "form_title": "Search the web…",
    "loader_text": "rewarding begins…",

    "block_help_text1": "celebrity or film to award",
    "block_help_text2": "your photo",
    "block_help_text3": "your selfie-based Oscar",

    "download_modal_title": "Don’t stop here!",
    "download_modal_text": "How does it feel to have your own Oscar Awards?! Share your moment of glory on Instagram and inspire friends to post their winners too!",
    "download_modal_text_loader": "Download started…",

    "btn_choose_foto": "Choose photo",
    "btn_choose_device_foto": "Choose from Gallery",
    "btn_start": "Start awarding",
    "btn_another_foto": "Change photo",
    "btn_download_foto": "Download",
    "btn_got_it": "Got it",
    "btn_download_again": "Download again",
    "btn_search": "Search",
    "btn_continue": "Continue",

    // todo
    "image_load_failure_modal__message": "Failure to load the selected image. Try to select another one.",
    "image_load_failure_modal__button": "Select another image",

    "error_modal__default_message": "Something went wrong. Try to reload the page or check back later.",
    "error_modal__network_message": "Please check your network connection.",

    "error__api_code_1": "Internal error",
    "error__api_code_2": "Incorrect params",
    "error__api_code_3": "Processing failure",
    "error__api_code_401": "Unauthorized request",
    "error__api_code_404": "Not found",
    "error__api_code_410": "The requested content is deleted",
    "error__api_code_415": "The selected file is in an invalid format",
  },
};
