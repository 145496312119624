import md5 from "md5";

export function utf8ToBase64(str) {
  str = (str + '');

  return window.btoa(urlencode(str));
}

export function urlencode(str) {
  str = (str + '');

  return encodeURIComponent(str)
    .replace(/!/g, '%21')
    .replace(/'/g, '%27')
    .replace(/\(/g, '%28')
    .replace(/\)/g, '%29')
    .replace(/\*/g, '%2A')
    .replace(/~/g, '%7E')
    .replace(/%20/g, '+');
}

export function appendQueryParamToURL(_, key, value) {
  const url = new URL(_);
  url.searchParams.append(key, value);
  return url.toString();
}

export function getLocationQueryObject() {
  return parseQuery(window.location.search.substring(1));
}

export function parseQuery(str) {
  const res = {};

  if (str.length === 0) {
    return res;
  }

  str.split("&").forEach((param) => {
    const data = param.split("=");
    res[decodeURIComponent(data[0])] = decodeURIComponent(data[1]);
  });

  return res;
}

export function copyTextFromElement(element) {
  element.select();
  element.setSelectionRange(0, 99999);

  try {
    document.execCommand("copy");
  } catch (e) {
    return false;
  }

  return true;
}

export function copyTextWithInvisibleElement(text) {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);

  textArea.focus();
  textArea.select();
  textArea.setSelectionRange(0, 99999);

  try {
    document.execCommand('copy');
  } catch (err) {
    console.error('Oops, unable to copy', err);
    return false;
  }

  document.body.removeChild(textArea);
  return true;
}

export function buildClassNamesString(obj) {
  return Object.keys(obj).filter(key => obj[key]).join(" ");
}

export function generateHash() {
  return md5([
    Math.random(),
    Date.now(),
    window.navigator.platform,
    window.navigator.userAgent,
    window.navigator.languages.join("/"),
    window.screen.width,
    window.screen.height,
  ].join("+"));
}