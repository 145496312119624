import "core-js/features/array/find";
import "core-js/features/array/includes";
import "core-js/features/string/starts-with";
import "core-js/features/object/assign";
import axios from "axios";

// eslint-disable-next-line
Array.prototype.random = function() {
  return this[Math.floor(this.length * Math.random())];
};

// eslint-disable-next-line
Array.prototype.count = function(predicate, thisArg) {
  return this.filter(predicate, thisArg).length;
};

require("./config");
require("./webview");

window.axios = axios.create();
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
window.axios.defaults.headers.common["X-Client-Token"] = window.clientConfig.token;

require("./app");