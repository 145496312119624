export class Suggestion {

  _data = {};

  constructor(data) {
    this._data = data;
  }

  get id() {
    return this._data.id;
  }

  get key() {
    return this._data.key;
  }

  get term() {
    return this._data.term;
  }

  get weight() {
    return this._data.weight;
  }
}