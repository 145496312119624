export class Task {

  static STATUS_FAILED = -1;
  static STATUS_PENDING = 0;
  static STATUS_PROCESSED = 1;

  _data = {};

  constructor(data) {
    this._data = data;
  }

  get id() {
    return this._data.id;
  }

  get status() {
    return this._data.status;
  }

  get result() {
    return this._data.result;
  }

  get isPending() {
    return this.status === Task.STATUS_PENDING;
  }

  get isProcessed() {
    return this.status === Task.STATUS_PROCESSED;
  }

  get isFailed() {
    return this.status === Task.STATUS_FAILED;
  }
}