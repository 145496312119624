export function debounce(name, delay, func) {
  window.appDebounceTimers = window.appDebounceTimers || {};
  clearTimeout(window.appDebounceTimers[name]);
  window.appDebounceTimers[name] = setTimeout(func, delay);

  return window.appDebounceTimers[name];
}

export function assetUrl(path, withSuffix = true) {
  const suffix = process.env.REACT_APP_ASSETS_VERSION
    ? ("?" + process.env.REACT_APP_ASSETS_VERSION)
    : "";

  return `${window.appConfig.paths.assets}/${path}${withSuffix ? suffix : ""}`;
}

export function when(key, map) {
  return map.hasOwnProperty(key) ? map[key] : undefined;
}